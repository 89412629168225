import React, { useRef, useState, useReducer, useEffect } from "react";

import { TweenOneGroup } from "rc-tween-one";
import { PlusOutlined } from "@ant-design/icons";
import {
  Input,
  Select,
  Button,
  Row,
  Form,
  Col,
  Checkbox,
  notification,
  Tag,
} from "antd";

import {
  organizationType,
  timeZone,
  organizationRegion,
} from "../../constants/common";
import StorageService from "../../services/storage";
import APIService from "../../services/api";

// const DEPARTMENTS = ['Board', 'Executives', 'Department Administrators', 'Patient Care Managers', 'Service Providers', 'Move up in the Hospital Hierarchy'];
const reducer = (state, action) => {
  switch (action.type) {
    case "PMP_ONLY":
      return { ...state, isPMPOnly: action?.isPMPOnly };
    case "TELEMEDICINE":
      return {
        ...state,
        isTeleMedicineServices: action?.isTeleMedicineServices,
      };
    case "HIDE_CHAT":
      return { ...state, isHideChat: action?.isHideChat };
    case "NOTIFICATION_BELL":
      return { ...state, isNotificationBell: action?.isNotificationBell };
    case "E_REGISTRATION":
      return { ...state, isERegistration: action?.isERegistration };
    case "CARE_PLUS":
      return { ...state, isCarePlus: action?.isCarePlus };
    case "PAYMENTS":
      return { ...state, isPayments: action?.isPayments };
    case "Referrals":
      return { ...state, isReferrals: action?.isReferrals };
    case "CAREERS":
      return { ...state, isCareers: action?.isCareers };
    case "OUTREACH":
      return { ...state, isOutreach: action?.isOutreach };
    case "INSURANCES":
      return { ...state, isInsurances: action?.isInsurances };
    case "GROWTH_TOOLS":
      return { ...state, isGrowthTools: action?.isGrowthTools };
    case "SHOW_BI_CONFIG_DASHBOARD":
      return { ...state, showBIConfigDashboard: action?.showBIConfigDashboard };
    case "UPDATE_LATITUDE":
      return { ...state, latitude: action?.latitude };
    case "UPDATE_LONGITUDE":
      return { ...state, longitude: action?.longitude };
    default:
      return state;
  }
};

const ehrOptions = [
  { label: 'All Scripts', value: 'allscripts' },
  { label: 'Aprima', value: 'aprima' },
  { label: 'Athena', value: 'athena' },
  { label: 'CPSI', value: 'cpsi' },
  { label: 'Dr Chrono', value: 'drchrono' },
  { label: 'ECW', value: 'ecw' },
  { label: 'Epic', value: 'epic' },
  { label: 'GE', value: 'ge' },
  { label: 'ModMed', value: 'modmed' },
  { label: 'NextGen', value: 'nextgen' },
  { label: 'Practice Fusion', value: 'fusion' },
  { label: 'Meditech', value: 'meditech' },
].sort((a, b) => a.label.localeCompare(b.label));

function EditOrganizationTab({ id, data, departmentsData }) {
  const {
    teleMedicineServices,
    PMPOnly,
    steerAppHideChat,
    steerAppHideNotificationBell,
    steerAppHideERegistration,
    steerAppHideCarePlus,
    steerAppHidePayments,
    steerAppHideReferrals,
    steerAppHideCareers,
    steerAppHideOutreach,
    steerAppHideGrowthTools,
    hideInsurancePage,
    biConfig,
    location,
  } = data;
  const { Option } = Select;
  const [form] = Form.useForm();
  const [allOrganizations, setAllOrganizations] = useState([]);

  const fetchAllOrganizations = async () => {
    try {
      const response = await APIService.requestWithToken(
        "api/v1/admin/org/list",
        "get",
        {
          query: null,
          select: JSON.stringify(["_id", "name"]),
          sort: JSON.stringify({ name: 1, createdAt: -1 }),
        }
      );
      if (response && response.res && response.res.data && response.res.data.list) {
        setAllOrganizations(response.res.data.list);
      }
    } catch (error) {
      console.error("Error fetching all organizations:", error);
    }
  };

  useEffect(() => {
    fetchAllOrganizations();
  }, []);
  // const [organizationName, setOrganizationName] = useState("");
  // const [abbreviationName, setAbbreviationName] = useState("")
  // const [street, setStreet] = useState("")
  // const [city, SetCity] = useState("")
  // const [state, setState] = useState("")
  // const [zipCode, setZipCode] = useState("")
  // const [phoneNumber, setPhoneNumber] = useState("")
  // const [faxNumber, setFaxNumber] = useState("")
  // const [Zone, setZone] = useState("");
  // const [facilityUid, setFacilityUid] = useState("")
  // const [facilityCode, setFacilityCode] = useState("")
  // const [orgType, setOrgType] = useState("");
  const [tags, setTags] = useState(
    data?.sourceOrganizationIds ? data?.sourceOrganizationIds : []
  );
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef(null);

  const [childOrgs, setChildOrgs] = useState(data?.childOrgs?.map(org => org._id || org) || []);

  const initialStats = {
    isPMPOnly: PMPOnly ? true : false,
    isTeleMedicineServices: teleMedicineServices ? true : false,
    isHideChat: steerAppHideChat ? true : false,
    isNotificationBell: steerAppHideNotificationBell ? true : false,
    isERegistration: steerAppHideERegistration ? true : false,
    isCarePlus: steerAppHideCarePlus ? true : false,
    isPayments: steerAppHidePayments ? true : false,
    isReferrals: steerAppHideReferrals ? true : false,
    isCareers: steerAppHideCareers ? true : false,
    isOutreach: steerAppHideOutreach ? true : false,
    isInsurances: hideInsurancePage ? true : false,
    isGrowthTools: steerAppHideGrowthTools ? true : false,
    showBIConfigDashboard: biConfig?.showDashboard ? true : false,
    latitude: location?.coordinates[1],
    longitude: location?.coordinates[0],
  };

  const [state, dispatch] = useReducer(reducer, initialStats);
  // const [selectedItems, setSelectedItems] = useState([]);
  // const [departments, setDepartments] = useState([])

  // const filteredOptions = departmentsData.filter((department) => !selectedItems.includes(department.name));

  const openNotification = () => {
    notification.open({
      message: "Updated Successfully",
    });
  };

  const onFinish = () => {
    form
      .validateFields()
      .then(async (values) => {
        let userData = StorageService.getData("user");
        // let newDepartments = values?.departments ? values?.departments.filter((value) => value.length !== 24) : []
        let requestObj = {
          ...values,
          userId: userData?._id,
          organizationId: id,
          organizationName: values?.name || data?.name,
          organizationType: values?.organizationType || data?.organizationType,
          region: values?.region || null,
          sourceOrganizationIds: tags,
          childOrgs: childOrgs,
          teleMedicineServices: state?.isTeleMedicineServices,
          PMPOnly: state?.isPMPOnly,
          steerAppHideChat: state?.isHideChat,
          steerAppHideNotificationBell: state?.isNotificationBell,
          steerAppHideERegistration: state?.isERegistration,
          steerAppHideCarePlus: state?.isCarePlus,
          steerAppHidePayments: state?.isPayments,
          steerAppHideReferrals: state?.isReferrals,
          steerAppHideCareers: state?.isCareers,
          steerAppHideOutreach: state?.isOutreach,
          hideInsurancePage: state?.isInsurances,
          steerAppHideGrowthTools: state?.isGrowthTools,
          biConfig: {
            showDashboard: state?.showBIConfigDashboard,
          },
          ...(state?.longitude && state?.latitude && {
            location: {
              coordinates: [state?.longitude, state?.latitude],
              type: "Point",
            },
          }),
          // departments: values?.departments ? values?.departments.filter((value) => value.length === 24) : [],
          // newDepartments
        };
        const response = await APIService.requestWithToken(
          "api/v1/organization/addOrEditOrganization",
          "post",
          requestObj
        );

        if (
          response?.res?.message.includes("organization update successfully.")
        ) {
          openNotification();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // const handleChange = (value) => {
  //   console.log(`selected ${value}`);
  // };

  // const onFinish = () => {
  //   // setLoading(true);
  //   let userData = StorageService.getData('user')
  //   let requestObj = {
  //     // organizationName: data?.organizationName ? data?.organizationName : organizationName,
  //     organizationName: organizationName || data?.name,
  //     abbreviationName: abbreviationName || data?.abbreviationName,
  //     organizationType: orgType || data.organizationType,
  //     facilityUid: facilityUid || data.facilityUid,
  //     facilityCode: facilityCode || data.facilityCode,
  //     userId: userData._id,
  //     organizationId: data.organizationId,
  //     PMPOnly: isPMPOnly,
  //     teleMedicineServices: isTeleMedicineServices,
  //     street: street || data.street,
  //     city: city || data.city,
  //     state: state || data.state,
  //     zipCode: zipCode || data.zipCode,
  //     phoneNumber: phoneNumber || data.phoneNumber,
  //     faxNumber: faxNumber || data.faxNumber,
  //     timeZone: Zone || data.timeZone,
  //     selectedItems: selectedItems,
  //   }
  //   APIService.requestWithToken("api/v1/organization/addOrEditOrganization", "post", requestObj)
  // };

  const handleClose = (removedTag) => {
    const newTags = tags.filter((tag) => tag !== removedTag);
    setTags(newTags);
  };

  const showInput = () => {
    setInputVisible(true);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = () => {
    if (inputValue && tags.indexOf(inputValue) === -1) {
      setTags([...tags, inputValue]);
    }

    setInputVisible(false);
    setInputValue("");
  };

  const forMap = (tag) => {
    const tagElem = (
      <Tag
        closable
        onClose={(e) => {
          e.preventDefault();
          handleClose(tag);
        }}
      >
        {tag}
      </Tag>
    );
    return (
      <span
        key={tag}
        style={{
          display: "inline-block",
        }}
      >
        {tagElem}
      </span>
    );
  };

  const tagChild = tags.map(forMap);

  return (
    <>
      <Form
        form={form}
        initialValues={data}
        name="control-hooks"
        layout="vertical"
        onFinish={onFinish}
      >
      <Row justify="space-between">
      <Col xs={7} md={11}>
          <Form.Item
            name="clientName"
            label="Client Name"
          >
            <Input placeholder="Enter Client Name" />
          </Form.Item>
        </Col>
        <Col xs={7} md={11}>
          <Form.Item
            name="deactivated"
            label="Deactivated"
            valuePropName="checked"
            initialValue={false}
          >
            <Checkbox />
          </Form.Item>
        </Col>
      </Row>
        <Form.Item
          name="name"
          label="Organization Name"
          rules={[{ required: true }]}
        // onChange={(e) => {
        //   setOrganizationName(e.target.value)
        // }}
        >
          <Input placeholder="Organization Name" />
        </Form.Item>
        <Form.Item
          name="abbreviationName"
          label="Abbreviation Name"
          rules={[{ required: true }]}
        // onChange={(e) => setAbbreviationName(e.target.value)}
        >
          <Input placeholder="Abbreviation Name" />
        </Form.Item>

        <Form.Item
          name="ehr"
          label="EHR"
        >
          <Select placeholder="Select EHR">
            {ehrOptions.map(option => (
              <Select.Option key={option.value} value={option.value}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="website"
          label="Website"
        >
          <Input placeholder="Website" />
        </Form.Item>

        <Row justify="space-between">
          <Col xs={7} md={11}>
            <Form.Item
              name="street"
              label="Street Address"
            // onChange={(e) => setStreet(e.target.value)}
            >
              <Input placeholder="Street Address" />
            </Form.Item>
          </Col>

          <Col xs={7} md={11}>
            <Form.Item
              name="city"
              label="City"
            // onChange={(e) => SetCity(e.target.value)}
            >
              <Input placeholder="City" />
            </Form.Item>
          </Col>
        </Row>

        <Row justify="space-between">
          <Col xs={7} md={11}>
            <Form.Item
              name="state"
              label="State"
            // onChange={(e) => setState(e.target.value)}
            >
              <Input placeholder="State" />
            </Form.Item>
          </Col>

          <Col xs={7} md={11}>
            <Form.Item
              name="zipCode"
              label="Zip Code"
            // onChange={(e) => setZipCode(e.target.value)}
            >
              <Input placeholder="Zip Code" />
            </Form.Item>
          </Col>
        </Row>

        <Row justify="space-between">
          <Col xs={7} md={11}>
            <Form.Item
              label="Latitude"

            >
              <Input
                placeholder="Latitude"
                type="number"
                value={state?.latitude}
                onChange={(e) => {
                  dispatch({
                    type: "UPDATE_LATITUDE",
                    latitude: e.target.value ? Number(e.target.value) : null,
                  })
                }}
              />
            </Form.Item>
          </Col>

          <Col xs={7} md={11}>
            <Form.Item
              label="Longitude"
            >
              <Input
                placeholder="Longitude"
                type="number"
                value={state?.longitude}
                onChange={(e) => {
                  dispatch({
                    type: "UPDATE_LONGITUDE",
                    longitude: e.target.value ? Number(e.target.value) : null,
                  })
                }} />
            </Form.Item>
          </Col>
        </Row>

        <Row justify="space-between">
          <Col xs={7} md={11}>
            <Form.Item
              name="phoneNumber"
              label="Phone Number"
            // onChange={(e) => setPhoneNumber(e.target.value)}
            >
              <Input placeholder="Phone Number" />
            </Form.Item>
          </Col>

          <Col xs={7} md={11}>
            <Form.Item
              name="faxNumber"
              label="Fax"
            // onChange={(e) => setFaxNumber(e.target.value)}
            >
              <Input placeholder="Fax" />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="space-between">
          <Col xs={7} md={11}>
            <Form.Item
              name="timeZone"
              label="TimeZone"
            // rules={[{ required: true, message: "Organization type is required." }]}
            >
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Select a TimeZone"
                optionFilterProp="children"
                // onChange={(e) => setZone(e)}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {Object.entries(timeZone).map((group) => (
                  <Option key={group[1]} value={group[1]}>
                    {`${group[1]
                      // .replace(/([A-Z])/g, " $1")
                      .trim()
                      .toUpperCase()}`}
                  </Option>
                ))}
                {/* {Object.entries(timeZone).map((group) => (
                <Option key={group[1]?.timezone} value={group[1]?.timezone}>
                  {`${group[1].timezone
                    // .replace(/([A-Z])/g, " $1")
                    .trim()
                    .toUpperCase()}`}
                </Option>
              ))} */}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={7} md={11}>
            <Form.Item name="region" label="Organization Region">
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Select a Region"
                optionFilterProp="children"
                allowClear
                // onChange={(e) => setOrgType(e)}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {Object.entries(organizationRegion).map((group) => (
                  <Option key={group[0]} value={group[1]}>
                    {`${group[0]}`}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row justify="space-between">
          <Col xs={7} md={11}>
            <Form.Item
              name="facilityUid"
              label="Facility Uid"
            // onChange={(e) => setFacilityUid(e.target.value)}
            >
              <Input placeholder="Facility Uid" />
            </Form.Item>
          </Col>

          <Col xs={7} md={11}>
            <Form.Item
              name="CarePlus incoming InBasket"
              label="CarePlus incoming InBasket"
            // onChange={(e) => setFacilityCode(e.target.value)}
            >
              <Input placeholder="CarePlus incoming InBasket" />
            </Form.Item>
          </Col>
        </Row>

        <Row justify="space-between">
          <Col xs={7} md={11}>
            <Form.Item
              name="departments"
              label="Departments"
            // onChange={(e) => setDepartments(e.target.value)}
            >
              <>
                {!inputVisible && (
                  <Tag onClick={showInput} className="site-tag-plus">
                    <PlusOutlined /> New Departments
                  </Tag>
                )}
                {inputVisible && (
                  <Input
                    ref={inputRef}
                    type="text"
                    size="medium"
                    placeholder="Departments"
                    maxLength={50}
                    // rules={[{ required: true, message: "Only Number allowed" }]}
                    style={{
                      width: "100%",
                    }}
                    value={inputValue}
                    onChange={handleInputChange}
                    onBlur={handleInputConfirm}
                    onPressEnter={handleInputConfirm}
                  />
                )}
                <div style={{ marginTop: 16 }}>
                  <TweenOneGroup
                    enter={{
                      scale: 0.8,
                      opacity: 0,
                      type: "from",
                      duration: 100,
                    }}
                    onEnd={(e) => {
                      if (e.type === "appear" || e.type === "enter") {
                        e.target.style = "display: inline-block";
                      }
                    }}
                    leave={{
                      opacity: 0,
                      width: 0,
                      scale: 0,
                      duration: 200,
                    }}
                    appear={false}
                  >
                    {tagChild}
                  </TweenOneGroup>
                </div>
              </>
              {/* <Select
                mode="tags"
                placeholder="Select Departments"
                // value={selectedItems}
                // onChange={handleChange}
                // onChange={setSelectedItems}
                style={{ width: '100%' }}
                tokenSeparators={[',']}
              >
                <Select.Option > </Select.Option >
                {filteredOptions.map((item, key) => {
                  return (
                    <Select.Option key={key?._id} value={item._id}>
                      {item?.name}
                    </Select.Option >
                  )
                })}
              </Select> */}
            </Form.Item>
          </Col>

          <Col xs={7} md={11}>
            <Form.Item
              name="organizationType"
              label="Organization Type"
              rules={[
                { required: true, message: "Organization type is required." },
              ]}
            >
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Select a Type"
                optionFilterProp="children"
                // onChange={(e) => setOrgType(e)}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {Object.entries(organizationType).map((group) => (
                  <Option key={group[0]} value={group[1]}>
                    {`${group[0]
                      .replace(/([A-Z])/g, " $1")
                      .trim()
                      .toUpperCase()}`}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        {/* <Col xs={7} md={11}>
          <Form.Item
            name="organizationType"
            label="Organization Type"
            rules={[{ required: true, message: "Organization type is required." }]}
          >
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Select a Type"
              optionFilterProp="children"
              onChange={(e) => setOrgType(e)}
              filterOption={(input, option) =>
                option.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {Object.entries(organizationType).map((group) => (
                <Option key={group[0]} value={group[1]}>{`${group[0]
                  .replace(/([A-Z])/g, " $1")
                  .trim()
                  .toUpperCase()}`}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col> */}
        <Row style={{ marginBottom: "2rem" }}>
          <Col xs={12} md={6}>
            <Checkbox
              style={{ marginLeft: "8px" }}
              checked={state?.isPMPOnly}
              onChange={(e) =>
                dispatch({ type: "PMP_ONLY", isPMPOnly: !state?.isPMPOnly })
              }
            >
              PMPOnly
            </Checkbox>
          </Col>
          <Col xs={12} md={6}>
            <Checkbox
              checked={state?.isTeleMedicineServices}
              onChange={(e) =>
                dispatch({
                  type: "TELEMEDICINE",
                  isTeleMedicineServices: !state?.isTeleMedicineServices,
                })
              }
            >
              TeleMedicine Services
            </Checkbox>
          </Col>
          <Col xs={12} md={6}>
            <Checkbox
              checked={state?.isHideChat}
              onChange={(e) =>
                dispatch({ type: "HIDE_CHAT", isHideChat: !state?.isHideChat })
              }
            >
              Chat page
            </Checkbox>
          </Col>
          <Col xs={12} md={6}>
            <Checkbox
              checked={state?.isNotificationBell}
              onChange={(e) =>
                dispatch({
                  type: "NOTIFICATION_BELL",
                  isNotificationBell: !state?.isNotificationBell,
                })
              }
            >
              Notification Bell
            </Checkbox>
          </Col>
          <Col xs={12} md={6}>
            <Checkbox
              style={{ marginLeft: "8px" }}
              checked={state?.isERegistration}
              onChange={(e) =>
                dispatch({
                  type: "E_REGISTRATION",
                  isERegistration: !state?.isERegistration,
                })
              }
            >
              E Registration
            </Checkbox>
          </Col>
          <Col xs={12} md={6}>
            <Checkbox
              checked={state?.isCarePlus}
              onChange={(e) =>
                dispatch({ type: "CARE_PLUS", isCarePlus: !state?.isCarePlus })
              }
            >
              Care Plus
            </Checkbox>
          </Col>
          <Col xs={12} md={6}>
            <Checkbox
              checked={state?.isPayments}
              onChange={(e) =>
                dispatch({ type: "PAYMENTS", isPayments: !state?.isPayments })
              }
            >
              Payments
            </Checkbox>
          </Col>
          <Col xs={12} md={6}>
            <Checkbox
              checked={state?.isReferrals}
              onChange={(e) =>
                dispatch({
                  type: "Referrals",
                  isReferrals: !state?.isReferrals,
                })
              }
            >
              Referrals
            </Checkbox>
          </Col>
          <Col xs={12} md={6}>
            <Checkbox
              style={{ marginLeft: "8px" }}
              checked={state?.isCareers}
              onChange={(e) =>
                dispatch({ type: "CAREERS", isCareers: !state?.isCareers })
              }
            >
              Careers
            </Checkbox>
          </Col>
          <Col xs={12} md={6}>
            <Checkbox
              checked={state?.isOutreach}
              onChange={(e) =>
                dispatch({ type: "OUTREACH", isOutreach: !state?.isOutreach })
              }
            >
              Outreach
            </Checkbox>
          </Col>
          <Col xs={12} md={6}>
            <Checkbox
              checked={state?.isInsurances}
              onChange={(e) =>
                dispatch({
                  type: "INSURANCES",
                  isInsurances: !state?.isInsurances,
                })
              }
            >
              Insurances
            </Checkbox>
          </Col>
          <Col xs={12} md={6}>
            <Checkbox
              checked={state?.isGrowthTools}
              onChange={(e) =>
                dispatch({
                  type: "GROWTH_TOOLS",
                  isGrowthTools: !state?.isGrowthTools,
                })
              }
            >
              Growth Tools
            </Checkbox>
          </Col>

          <Col xs={12} md={6}>
            <Checkbox
              style={{ marginLeft: "8px" }}
              checked={state?.showBIConfigDashboard}
              onChange={(e) =>
                dispatch({
                  type: "SHOW_BI_CONFIG_DASHBOARD",
                  showBIConfigDashboard: !state?.showBIConfigDashboard,
                })
              }
            >
              BI Config Dashboard
            </Checkbox>
          </Col>
        </Row>

        <Row justify="space-between">
          <Col xs={24} md={24}>
            <Form.Item
              name="childOrgs"
              label="Child Organizations"
            >
              <Select
                mode="multiple"
                style={{ width: '100%' }}
                placeholder="Select child organizations"
                value={childOrgs}
                onChange={(selectedOrgs) => setChildOrgs(selectedOrgs)}
                optionFilterProp="children"
              >
                {allOrganizations.map((org) => (
                  <Select.Option key={org._id} value={org._id}>
                    {org.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          wrapperCol={{
            offset: 11,
            span: 16,
          }}
        >
          <div>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </div>
        </Form.Item>
      </Form>
    </>
  );
}

export default EditOrganizationTab;
